import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'flag-icon-css/css/flag-icon.min.css'
import VueScrollTo from 'vue-scrollto'
import '@/plugins/legacy/hctr.js'
import 'vue-resize/dist/vue-resize.css'

Vue.use(VueScrollTo)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
