import { cloneDeep } from 'lodash-es'
import axios from 'axios'
import qs from 'qs'

export default async (call = {}, hooks = {}) => {
  if (call.loaded) call.refreshing = true
  call.loading = true

  if (hooks.before) {
    hooks.before(cloneDeep(call))
  }

  // do the call
  let api

  if (!call.url.includes('http')) {
    call.url = `${process.env.VUE_APP_HCTR_API}${call.url}`
  }

  call.request.headers = {
    ...call.request.headers
  }

  if (process.env.VUE_APP_HCTR_APP_ID) {
    call.request.headers['hctr-application'] = process.env.VUE_APP_HCTR_APP_ID
  }

  if (window.localStorage['hctr-session']) {
    call.request.headers['hctr-session'] = window.localStorage['hctr-session']
  }

  if (window.localStorage['hctr-token']) {
    call.request.headers['Authorization'] = `Bearer ${window.localStorage['hctr-token']}`
  }

  try {
    api = await axios({
      url: call.url,
      method: call.method,
      headers: call.request.headers,
      params: call.request.params,
      data: call.request.data,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  } catch (error) {
    console.log(error.message)
    if (error.response) {
      call.response = error.response.data
    } else if (error.request) {
      call.response = {
        status: 'error',
        message: 'The request was made but no response was received',
        data: []
      }
    } else {
      call.response = {
        status: 'error',
        message: 'something happened processing the script',
        data: []
      }
    }
    call.refreshing = false
    call.loading = false
    return
  }

  if (call.url.split('/').pop() === 'new') {
    call.request.data = cloneDeep(api.data.data)
    call.model = cloneDeep(api.data.data)
    delete call.model._id
    delete call.model.createdAt
    delete call.model.updatedAt
    delete call.model._v
  }

  if (api) {
    call.response = api.data
  }

  call.loaded = true
  call.refreshing = false
  call.loading = false
}
