import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/embed/animal/:eartag',
    name: 'embed-animal',
    component: () => import(/* webpackChunkName: "embed-animal" */ '@/views/Animal.vue')
  },
  {
    path: '/embed/:embed',
    name: 'Embed',
    component: () => import('@/views/Embed.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
