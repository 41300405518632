export default (call = {}) => {
  return Object.assign(
    {
      loading: false,
      loaded: false,
      refreshing: false,
      url: '',
      method: 'GET',
      model: {},
      options: {},
      request: {
        headers: {
          'hctr-application': process.env.VUE_APP_HCTR_APP_ID,
          'hctr-session': window.localStorage['hctr-session'],
          Authorization: `Bearer ${window.localStorage['hctr-token']}`
        },
        params: {},
        data: {}
      },
      response: {
        status: '',
        message: '',
        data: []
      }
    },
    call
  )
}
