import Vue from 'vue'
import api from './api'
import call from './call'
import { findIndex } from 'lodash-es'

Vue.mixin({
  computed: {
    $config() {
      return {
        api: process.env.VUE_APP_HCTR_API
      }
    }
  }
})

Vue.prototype.$api = api
Vue.prototype.$call = call

Vue.prototype.$storage = {
  key(key) {
    return `hctr-${key}`
  },
  set(type = 'local', key, value) {
    value = JSON.stringify(value)
    window[`${type}Storage`][this.key(key)] = value
  },
  get(type = 'local', key) {
    let item = window[`${type}Storage`][this.key(key)]
    if (item) {
      item = JSON.parse(item)
    }
    return item
  },
  push(type = 'local', key, value) {
    let items = []
    if (window[`${type}Storage`][this.key(key)]) {
      items = JSON.parse(window[`${type}Storage`][this.key(key)])
    }
    items.push(value)
    window[`${type}Storage`][this.key(key)] = JSON.stringify(items)
  },
  unshift(type = 'local', key, value, options = {}) {
    let items = []
    if (window[`${type}Storage`][this.key(key)]) {
      items = JSON.parse(window[`${type}Storage`][this.key(key)])
    }
    if (options.unique) {
      let index = findIndex(items, value)
      console.log(index)
      if (index > -1) {
        items.splice(index, 1)
      }
    }
    items.unshift(value)
    window[`${type}Storage`][this.key(key)] = JSON.stringify(items)
  },
  local: {}
}
